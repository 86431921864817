import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { experiences, skills } from '../data/resume_data'
import '../components/component-style/resume.css'
import { Link } from 'gatsby'

const ResumePage = () => (
    <Layout>
        <SEO title="Resume" />
        <h1>Resume</h1>
        <div className="container">
            <div className="column">
                <div className="resumeBlock">
                    <h3>Education</h3>
                    <div className="company">
                        <h4>Georgia Institute of Technology</h4>
                        <p className="position">Atlanta, Ga</p>
                        <p className="position">BS in Computer Science</p>
                        <p className="position">
                            Concentrations in Intelligence and Media
                        </p>
                        <p className="position">Expected May 2021</p>
                    </div>
                </div>
                <div className="resumeBlock">
                    <h3>Technical Skills</h3>
                    {skills.map((skill, x) => (
                        <div key={x}>
                            <div className="company">
                                <h4>{skill.type}</h4>
                                {skill.subtype && (
                                    <span> | {skill.subtype}</span>
                                )}
                            </div>
                            {skill.items.map((item, i, items) => (
                                <span key={i}>
                                    {item}
                                    {i !== items.length - 1 && ' • '}
                                </span>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="resumeBlock">
                    <h3>Personal Projects</h3>
                    <p className="position">
                        Check out the <Link to="/projects">Projects Page</Link>{' '}
                        for details on my current endeavors!
                    </p>
                </div>
            </div>
            <div className="column">
                <div className="resumeBlock">
                    <h3>Experience</h3>
                    {experiences.map((exp, x) => (
                        <div key={x}>
                            <div className="company">
                                <h4>{exp.company}</h4>
                                <span> | {exp.dates}</span>
                            </div>
                            <p className="position">{exp.title}</p>
                            <ul>
                                {exp.descriptions.map((desc, i) => (
                                    <li key={i}>{desc}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </Layout>
)

export default ResumePage

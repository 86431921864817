export const experiences = [
    {
        company: 'GTRI Robolab',
        dates: '01/2020 - Present',
        title: 'Aquabot Researcher',
        descriptions: [
            'Design and prototype aquabot control systems using embedded microcontrollers',
            'Focus on software/hardware integration and position estimation using radio signal strength'
        ]
    },
    {
        company: 'Tidal Wave Auto Spa',
        dates: '10/2019 - Present',
        title: 'Software Subcontractor',
        descriptions: [
            'Designed and currently manage a web platform for managing Tidal Wave\'s collision avoidance system',
            'Built the frontend with React.js, the backend with Node and Express, and hosted it on Azure and Azure SQL'
        ]
    },
    {
        company: 'Asurion',
        dates: 'Summer 2018, 2019',
        title: 'Software Development Intern',
        descriptions: [
            'Used Node and React.js frameworks to build customer support solutions deployed worldwide',
            'Wrote and executed build scripts to deploy code to AWS'
        ]
    },
    {
        company: 'Gatech Aerospace',
        dates: '08/2017 - 05/2018',
        title: 'Small Satellite Researcher',
        descriptions: [
            'Led the Optics Subsytem team to develop imaging software and embedded hardware systems for the RANGE and TARGIT cubesats'
        ]
    },
    {
        company: 'Diversified',
        dates: '05/2016 - 04/2017',
        title: 'Junior Programmer',
        descriptions: [
            'Designed, built, and tested custom A/V integration software for conference rooms, lecture halls, and digital signage',
            'Created solutions for companies like Coca-Cola, Baptist Healthcare, and Sealed Air'
        ]
    }
]

export const skills = [
    {
        type: 'Programming',
        subtype: 'Web Tools',
        items: [
            'Javascript',
            'React',
            'Node',
            'Typescript',
            'AWS',
            'Azure',
            'HTML5',
            'CSS'
        ]
    },
    {
        type: 'Programming',
        subtype: 'Desktop Tools',
        items: [
            'Python',
            'Rust',
            'C++',
            'C#',
            'R',
            'SQL',
            'Java',
            'OpenCV',
            '.NET'
        ]
    },
    {
        type: 'Robotics',
        subtype: null,
        items: [
            'Arduino',
            'Raspberry Pi',
            '3D-Printing',
            'Prototyping',
            'PCB Design',
            'Imaging Systems',
            'CAD'
        ]
    },
    {
        type: 'Certifications',
        subtype: null,
        items: [
            'Licensed UAS Remote Pilot (FAA Part 107)'
        ]
    }
]
